.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#datePicker {
  width: 200px;
  vertical-align: middle;
  /* height: 40px; */
  /* display: inline; */
}

.push-right {
  margin-left: auto;
}

.code-block-container {
  position: relative;
}


.code-block-container {
  position: relative;
  /* Ensure the container fits the content */
}

.configuration-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Grey overlay */
  color: white; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  pointer-events: none; /* Prevents interaction with the content underneath */
}
